import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/notification";

class NotificationServices {
    getReadReceiptAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/readRecipt`, data, headers);

 
}
// eslint-disable-next-line
export default new NotificationServices();