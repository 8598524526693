import { createAsyncThunk } from "@reduxjs/toolkit";
import taskService from "../../Services/myTaskService";
import { responseMessageHandler } from "../../globals/components/notification";

import NotificationServices from "../../Services/NotificationServices";
export const getNotificationList = createAsyncThunk(
  "notification/getNotificationList",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getTaskAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
        // orderBy :"updatedAt"
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getReadReceipt= createAsyncThunk(
    "notification/getReadReceipt",
    async (data, thunkAPI) => {
      console.log(data)
      try {
        let res = await NotificationServices.getReadReceiptAPI(data);
  
        return res;
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.error) ||
          error.message ||
          error.toString();
        responseMessageHandler(error.response.data);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );