import {
  DASHBOARD_DEPT_ID,
  TEMPLATE_DEPT_ID,
  CONTENT_DEPT_ID,
  WORKFLOW_DEPT_ID,
  STAFF_DEPT_ID,
  COMPANY_DEPT_ID,
  VENDOR_DEPT_ID,
  TASK_MGMNT_DEPT_ID,
  AGGREEMENT_MGMNT_DEPT_ID,
  TASK_MGMNT_VENDOR_DEPT_ID,
  MY_CONTRACT_DEPT_ID,
  KYC_DEPT_ID,
  MY_TASK,
  OTHER_DEPT_ID,
  OTHER_VENDOR_DEPT_ID,
  HISTORICAL_DEPT_ID,
  LICENCE_DEPT_ID,
  OBLIGATION,
  MYOBLIGATION_DEPT_ID,
} from "../../../utils/department";
import taskIcon from "../../../assets/images/taskimgicon";

import {
  HomeOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  FileWordOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  AuditOutlined,
  SolutionOutlined,
 
  HddOutlined,
  IdcardOutlined,
  BookOutlined,
} from "@ant-design/icons";
import TaskIcon from "../../../assets/images/task";
import MyTaskIcon from "../../../assets/images/Mytask";
import ContractManagementIcon from "../../../assets/images/contractManagement";
import UserSettingIcon from "../../../assets/images/userSettingIcon";
const siderContent = [
  {
    svg: HomeOutlined,
    name: "dashboard",
    key: "dashboard",
    route: "/dashboard",
    menuIds: [999],
    dept_ids: [DASHBOARD_DEPT_ID],
    children: [],
  },
  {
    svg: TaskIcon,
    name: "task_management",
    key: "task_management",
    route: "/task_management",
    menuIds: [999],
    dept_ids: [TASK_MGMNT_DEPT_ID],
    children: [],
  },
  {
    svg: MyTaskIcon,
    name: "my_task",
    key: "my_task",
    route: "/my_task",
    menuIds: [999],
    dept_ids: [MY_TASK],
    children: [],
  },
  {
    svg:MyTaskIcon,
    name: "my_task",
    key: "task_mgmnt",
    route: "/my_task_vendor",
    menuIds: [999],
    dept_ids: [TASK_MGMNT_VENDOR_DEPT_ID],
    children: [],
  },
  {
    svg: AuditOutlined,
    name: "myobligation",
    key: "myobligation",
    route: "/myobligation",
    menuIds: [999],
    dept_ids: [MYOBLIGATION_DEPT_ID],
    children: [],
  },

  {
    svg: HddOutlined,
    name: "mycontract",
    key: "mycontract",
    route: "/mycontract",
    menuIds: [999],
    dept_ids: [MY_CONTRACT_DEPT_ID],
    children: [],
  },
  {
    svg: IdcardOutlined,
    name: "historical_contract",
    key: "historical_contract",
    route: "/historical_contract",
    menuIds: [999],
    dept_ids: [HISTORICAL_DEPT_ID],
    children: [],
  },
  {
    svg: ContractManagementIcon,
    name: "Contract Management",
    key: "contract_management",
    route: "/contract_management",
    menuIds: [999],
    dept_ids: [TEMPLATE_DEPT_ID, CONTENT_DEPT_ID, WORKFLOW_DEPT_ID, OBLIGATION],
    children: [
      {
        svg: FileDoneOutlined,
        name: "templates",
        key: "templates",
        route: "/templates",
        menuIds: [999],
        dept_ids: [TEMPLATE_DEPT_ID],
        children: [],
      },
      {
        svg: ContainerOutlined,
        name: "content",
        key: "content",
        route: "/content",
        menuIds: [999],
        dept_ids: [CONTENT_DEPT_ID],
        children: [],
      },
      {
        svg: AuditOutlined,
        name: "obligation",
        key: "obligation",
        route: "/obligation",
        menuIds: [999],
        dept_ids: [OBLIGATION],
        children: [],
      },
      {
        svg: FileWordOutlined,
        name: "workflow",
        key: "Workflow",
        route: "/Workflow",
        menuIds: [999],
        dept_ids: [WORKFLOW_DEPT_ID],
        children: [],
      },
     
    ],
  },
  {
    svg: UserSettingIcon,
    name: "Contract Admin",
    key: "contract_admin",
    route: "/contract_admin",
    menuIds: [999],
    dept_ids: [TEMPLATE_DEPT_ID, CONTENT_DEPT_ID, WORKFLOW_DEPT_ID],
    children: [
      {
        svg: "",
        name: "content_Services",
        key: "contentservices",
        route: "/content_settings",
        menuIds: [999],
        dept_ids: [999],
        children: [],
      },
      // {
      //   svg: "",
      //   name: "workflow_Services",
      //   key: "workflow_service",
      //   route: "/workflow_settings",
      //   menuIds: [999],
      //   dept_ids: [999],
      //   children: [],
      // },
      {
        svg: "",
        name: "obligation_Services",
        key: "obligation_Services",
        route: "/obligation_Services",
        menuIds: [999],
        dept_ids: [OBLIGATION],
        children: [],
      },
     
      {
        svg: "",
        name: "historical_Services",
        key: "historical_Services",
        route: "/historical_Services",
        menuIds: [999],
        dept_ids: [HISTORICAL_DEPT_ID],
        children: [],
      },
     
      {
        svg: "",
        name: "other_Services",
        key: "other_service",
        route: "/other_settings",
        menuIds: [999],
        dept_ids: [OTHER_DEPT_ID],
        children: [],
      },
      {
        svg: "",
        name: "other_Services",
        key: "other_vendorservice",
        route: "/other_vendorsettings",
        menuIds: [999],
        dept_ids: [OTHER_VENDOR_DEPT_ID],
        children: [],
      },
      
    ],
  },

  {
    svg: UserOutlined,
    name: "User Admin",
    key: "user_admin",
    route: "/user_admin",
    menuIds: [999],
    dept_ids: [TEMPLATE_DEPT_ID, CONTENT_DEPT_ID, WORKFLOW_DEPT_ID],
    children: [
      {
        svg: UserOutlined,
        name: "Staff",
        key: "staff",
        route: "/staff",
        menuIds: [999],
        dept_ids: [STAFF_DEPT_ID],
        children: [],
      },
      {
        svg: SolutionOutlined,
        name: "vendors",
        key: "vendor",
        route: "/vendor",
        menuIds: [999],
        dept_ids: [VENDOR_DEPT_ID],
        children: [],
      },
    ],
  },
  // {
  //   svg: "",
  //   name: "Communication",
  //   key: "chat",
  //   route: "/settings",
  //   menuIds: [999],
  //   dept_ids: [999],
  //   children: [],
  // },

  {
    svg: UsergroupAddOutlined,
    name: "company",
    key: "company",
    route: "/company",
    menuIds: [999],
    dept_ids: [COMPANY_DEPT_ID],
    children: [],
  },
  {
    svg: UsergroupAddOutlined,
    name: "agrrement_management",
    key: "agrrement_management",
    route: "/agrrement_management",
    menuIds: [999],
    dept_ids: [AGGREEMENT_MGMNT_DEPT_ID],
    children: [],
  },

  {
    svg: IdcardOutlined,
    name: "kyc",
    key: "kyc",
    route: "/kyc",
    menuIds: [999],
    dept_ids: [KYC_DEPT_ID],
    children: [],
  },
 
  {
    svg: BookOutlined,
    name: "planpackages",
    key: "planpackages",
    route: "/plan&packages",
    menuIds: [999],
    dept_ids: [LICENCE_DEPT_ID],
    children: [],
  },
  // {
  //   svg: "",
  //   name: "activity_log",
  //   key: "activity_log",
  //   route: "/activity_log",
  //   menuIds: [999],
  //   dept_ids: [999],
  //   children: [],
  // },
];
export default siderContent;
