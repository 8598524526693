import React, { Component } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import "antd/dist/antd.min.css";
import "./App.less";
import { IntlProvider } from "react-intl";
import { getChatRoomApi } from "./modules/users/userActions";
import { connect } from "react-redux";

import { verifyOtp } from "./modules/users/userActions";

const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.getCookiesData();
    this.state = {};
  }

  getCookiesData = () => {
    var allcookies = document.cookie;
    let cookiearray = allcookies.split("; ");
    let name = [];
    for (var i = 0; i < cookiearray.length; i++) {
      name.push(cookiearray[i].split("=")[0]);
    }
    if (
      localStorage.getItem("rememberMe") === "false" &&
      !name.includes("rememberMe")
    ) {
      localStorage.clear();
    }
    if (localStorage.getItem("customerToken")) {
      this.customerToken = localStorage.getItem("customerToken");
      let cust_emp_id = Number(localStorage.getItem("cust_emp_id"));
      // if (localStorage.getItem("customer")) {
      // let customer = localStorage.getItem("customer");
      let customer_id = localStorage.getItem("customer_id");
      const { pathname } = window.location;
      if (pathname.includes("basic_Details") && localStorage.getItem("otp")) {
        const data = {
          encryptedData: localStorage.getItem("encryptedData"),
          otp: localStorage.getItem("otp"),
        };
        this.props.dispatch(verifyOtp({ data, history: this.props.history }));
        // this.props.dispatch(
        //   getDetails({
        //     taskId: localStorage.getItem("taskId"),
        //     companyIds: localStorage.getItem("companyIds"),
        //     companyId: localStorage.getItem("companyId"),
        //     vendorId: localStorage.getItem("vendorId"),
        //   })
        // );
        // this.props.dispatch(
        //   getTaskcomments({
        //     limit: "10",
        //     page: "1",
        //     orderBy: "createdAt",
        //     sortBy: "DESC",
        //     is_vendor: true,
        //     taskId: localStorage.getItem("taskId"),
        //   })
        // );
        // this.props.dispatch(
        //   getTaskactivitylist({
        //     taskId: localStorage.getItem("taskId"),
        //     companyId: localStorage.getItem("companyId"),
        //   })
        // );
      } else if (
        !pathname.includes("guest-user") &&
        !pathname.includes("guest")
      ) {
        this.props.dispatch(
          getChatRoomApi({
            cust_emp_id: cust_emp_id,
            customer_id: customer_id,
          })
        );
      }
    }
  };
  render() {
    const { local } = this.props;
    const { _locale, translations } = local;
    const { pathname } = window.location;

  
    localStorage.setItem("prepath",pathname)
    return (
      <IntlProvider locale={_locale} messages={translations} defaultLocale="en">
        <div className="App">
          <Layout>
            <Content className="contents">
              <Router>
                <Routes {...this.props} />
              </Router>
            </Content>
          </Layout>
        </div>
      </IntlProvider>
    );
  }
}

export default connect((state) => ({
  local: state.local,
}))(App);
