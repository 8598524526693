//-------------------------------------------------All Api-------------------------------------------------

export const encrypt = true;
export const environment_key="dev"


// export const appServiceName = "https://q3dgr3q4-5000.inc1.devtunnels.ms";
export const appServiceName = "https://dev.backend.verifiablecontract.com"; //API Address Abhisek
export const appSocketService = "https://dev.backend.verifiablecontract.com";
export const PortalUrl = "https://dev.verifiablecontract.com";
// export const appServiceName = "https://demo.backend.verifiablecontract.com"; //API Address Abhisek
// export const appSocketService = "https://demo.backend.verifiablecontract.com"; //API Address Abhisek
// export const appServiceName = "http://localhost:5000"; //API Address Abhisek
// export const appSocketService = "http://localhost:5000";
