import { createAsyncThunk } from "@reduxjs/toolkit";
import TemplateServices from "./../../Services/TemplateServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { customActions } from "../Taskmanagement/Taskmanagement.Action";
import { getChatRoomApi } from "../users/userActions";
export const getTemplates = createAsyncThunk(
  "template/getTemplates",
  async (data, thunkAPI) => {
    try {
      let res = await TemplateServices.gettemplateAPI(
        data.is_all
          ? ""
          : {
              ...data,
              limit: data?.pageSize,
              page: data?.current,
            }
      );
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTemplate = createAsyncThunk(
  "template/createTemplate",
  async ({ recData, templatePagination, templateData }, thunkAPI) => {
    try {
      const res = await TemplateServices.createtemplateAPI(recData);
      thunkAPI.dispatch(getTemplates(templatePagination));
      thunkAPI.dispatch(customActions(res.data));
      // thunkAPI.dispatch(getChatRoomApi())
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async ({ recData, templatePagination }, thunkAPI) => {
    try {
      const res = await TemplateServices.createtemplateAPI(recData);
      thunkAPI.dispatch(getTemplates(templatePagination));
      // thunkAPI.dispatch(getChatRoomApi())

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletetemplate = createAsyncThunk(
  "template/deletetemplate",
  async ({ data, templatePagination }, thunkAPI) => {
    try {
      const res = await TemplateServices.deletetemplateApi(data);
      thunkAPI.dispatch(getTemplates(templatePagination));


      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
