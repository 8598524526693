import { createSlice } from "@reduxjs/toolkit";

import { getReadReceipt } from "./Notification.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,

  isVisible: false,

  isdrawerVisible: false,
  editRecord: {},
  type: "",

  totalCount: null,
  isNotificationUnread: false,
};
const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState: initialState,
  reducers: {
    setReadReciept: (state, { payload }) => {
      state.isNotificationUnread = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getReadReceipt.pending, (state, { payload }) => {})
      .addCase(getReadReceipt.fulfilled, (state, { payload }) => {
        state.isNotificationUnread = payload?.data?.notification;
      })
      .addCase(getReadReceipt.rejected, (state, { payload }) => {});
  },
});
export const {setReadReciept} = NotificationSlice.actions;

export default NotificationSlice.reducer;
