import taskService from "./../../Services/taskServices";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseMessageHandler } from "../components/notification";
import { SetNotificationModal } from "./layout.slice";
import { setReadReciept } from "../../modules/Notification/Notification.Slice";
export const getnotifications = createAsyncThunk(
  "task/getnotifications",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getnotificationsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getnotificationsList = createAsyncThunk(
  "layout/getnotificationsList",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getnotificationsListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      if (data?.isDefault) {
        thunkAPI.dispatch(SetNotificationModal({ visible: true }));
      }
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getnotificationsListV2 = createAsyncThunk(
  "layout/getnotificationsListV2",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getnotificationsListV2API({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      thunkAPI.dispatch(setReadReciept(false));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
